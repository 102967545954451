<template>
  <div class="home">
    <div class="container">
      <div class="d-covid">
        <Covid />
      </div>

      <div class="d-search"></div>
      <div class="d-btc">
        <Bitcoin />
      </div>
      <div class="d-todo">
        <Console />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const Bitcoin = () => import("@/components/Bitcoin.vue");
const Console = () => import("@/components/Console.vue");
const Covid = () => import("@/components/Covid.vue");
export default {
  name: "Home",

  components: {
    Bitcoin,
    Console,
    Covid
  }
};
</script>
<style scoped>
.container {
  background-color: #0c090d;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 60% 40%;
  height: 80vh;
}
.d-btc {
  background-color: #f15946;
  color: cornsilk;
  font-size: 18px;
  padding: 10px;
}
.d-covid {
  background-color: #f9c22e;
  padding: 10px;
  color: #0c090d;
}
.d-search {
  background-color: #53b3cb;
}
.d-todo {
  background-color: #0c090d;
  color: #ffffff;
  grid-column: 1/4;
  text-align: left;
  padding-left: 4px;
  font-size: 18px;
  height: 200px;
  overflow: scroll;
}
</style>
