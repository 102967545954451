import Vue from "vue";
import Vuex from "vuex";
import covidApi from "@/services/covidapi.js";

Vue.use(Vuex);
export default {
  state: {
    status: [],
    last: {},
    day: Number
  },
  getters: {
    myStatus: state => state.GET_STATUS,
    myLast: state => state.last,
    dayStat: state => state.day
  },
  mutations: {
    GET_STATUS: (state, res) => {
      state.status = res;
      state.last = res[res.length - 1];
      let first = res[res.length - 1].Cases;
      let flast = res[res.length - 2].Cases;
      state.day = first - flast;
    }
  },
  actions: {
    async getStatus({ commit }) {
      const res = await covidApi.getLive("Italy");
      commit("GET_STATUS", res.data);
    }
  }
};
