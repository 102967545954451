import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import bitcoin from "@/store/modules/bitcoin.js";
import covid from "@/store/modules/covid.js";
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    bitcoin,
    covid
  }
});
